/*jslint browser: true*/
/*jslint plusplus: true*/

import {
	createCookie, readCookie, eraseCookie,
	extend, createElementFromHtml, forEach
} from '@misc/utilities'

import BaseButton from '@misc/base-button'
import HoverIntent from '@misc/hoverintent'

declare var Y:any

class StartPage {

  public moduleBlockDefaults
  public moduluebersichtElem
  public progressElem
  public buttons
  public control_buttons

	constructor() {

		this.moduleBlockDefaults = {
			'theory': 'open',
			'practice': 'closed',
		}

		this.moduluebersichtElem = document.getElementById('moduluebersicht')
		this.progressElem = document.getElementById('progress')

		if(this.moduluebersichtElem && !document.body.classList.contains('courses-overview--vertical')) {
			this.buttons = {}
			this.initControlsButtons()
			this.initCourseOverview()

			Y.Global.on('dock:itemschanged', e => {
				this.initCourseOverview()
			});
		}
	}

	initControlsButtons() {
		const self = this

		// Instantiate and append all buttons
		let controlButtonsHtml = ''

		controlButtonsHtml += '<div class="viewcontrols btn-group">';
		controlButtonsHtml += '    <a class="button icon" id="ExpandUnits"><div class="icon"></div></a>';
		controlButtonsHtml += '</div>';

		this.control_buttons = createElementFromHtml(controlButtonsHtml)

		const controlsElem = document.querySelector('.coursesoverview__controls')

		if(controlsElem) {

			controlsElem.insertBefore(this.control_buttons, controlsElem.firstChild)

			this.buttons.expandUnits = new BaseButton(

				document.getElementById('ExpandUnits'),
				'expandUnits',

				{
					title: 'Details der Lerneinheiten ein-/ausklappen',

					target_cssclass: 'open_lock',

					actionPressed: function () {
						[ '.lerneinheit', 'fall' ].forEach(selector => {
							forEach(self.moduluebersichtElem.querySelectorAll(selector), element => {
								element.classList.add(this.target_cssclass)
							})
						})
					},

					actionReleased: function () {
						[ `.lerneinheit.${this.target_cssclass}`, `.fall.${this.target_cssclass}` ].forEach(selector => {
							forEach(self.moduluebersichtElem.querySelectorAll(selector), element => {
								element.classList.remove(this.target_cssclass)
							})
						})
					}
				}
			);
		}
	}

	initCourseOverview() {
		new HoverIntent(
			'#moduluebersicht .unit-type-theory',
			e => {
				const element = e.target.closest('.lerneinheit')
				if(element) {
					element.classList.add('open')
				}
			},
			e => {
				const element = e.target.closest('.lerneinheit')
				if(element) {
					element.classList.remove('open')
				}
			}
		)

    const moduleBlocks = this.moduluebersichtElem.querySelectorAll('.module-block')

    forEach(moduleBlocks, block => {
      this.courseOverviewEvenHeights(block)
    })
	}

	courseOverviewEvenHeights(moduleBlock) {
		var baseElem = moduleBlock,
			rowSizes:any[] = [],
			currentRow,
			currentElem,
			currentRowHeight,
			resizeElem,
			iRow,
			minSize = 0,
			maxSize,
			i,
			j;

		// Module loop
		const modules = baseElem.querySelectorAll('.module')
		let rows

		forEach(modules, elem => {

			iRow = 0;

			// Learning units loop
      rows = elem.querySelectorAll('.content > *, .moduletitle')

			forEach(rows, (rowElem, i) => {

				if (!rowSizes[iRow]) {
					rowSizes[iRow] = {
						elements: [],
						maxSize: minSize
					};
				}

				var resizeElem = i === 0 ?
					rowElem : resizeElem = rowElem.querySelector('.title')

				resizeElem.style.minHeight = 0

				currentRow  = rowSizes[iRow]
				currentRow.elements.push(resizeElem)
				delete resizeElem.style.height

				currentRowHeight = resizeElem.getBoundingClientRect().height

				if (currentRow.maxSize < currentRowHeight) {
					currentRow.maxSize = currentRowHeight
				}

				iRow++
			});
		});

		let titleElem

		// set row sizes
		forEach(rowSizes, rowSize => {
			forEach(rowSize.elements, (rowSizeElement, i) => {
				titleElem = rowSizeElement.querySelector('.title')
				resizeElem = titleElem || rowSizeElement
				maxSize = rowSize.maxSize
				//resizeElem.style.minHeight = `${maxSize}px`
				rowSizeElement.style.minHeight = `${maxSize}px`
			})
		})
	}
}

export default StartPage
