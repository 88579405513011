import { compatibility } from '@misc/compatibility'
import StartPage from '@misc/startpage'
import configHyphenopoly from '@misc/hyphenopoly-config'

window.addEventListener('DOMContentLoaded', function() {
  new StartPage()
})

compatibility()
configHyphenopoly()
